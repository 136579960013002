(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _headerFN = function _headerFN() {
  var $header = document.querySelector('.header');
  var scrollTop = window.scrollY,
      offsetTop = 60;

  if (scrollTop >= offsetTop) {
    $header.classList.add('jsOnScroll');
    document.body.classList.add('jsOnScroll-header');
  } else {
    $header.classList.remove('jsOnScroll');
    document.body.classList.remove('jsOnScroll-header');
  }
};

var _default = _headerFN;
exports["default"] = _default;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var navToggle = function navToggle() {
  var $navToggle = document.querySelector('.navbar-toggle');
  var $header = document.querySelector('.header');
  var $body = document.body;
  var $backdrop = document.querySelector('.menu-backdrop'); // for iOS bugs

  function preventDefault(e) {
    e.preventDefault();
  }

  function disableScroll() {
    document.body.addEventListener('scroll', preventDefault, {
      passive: true
    });
  }

  function enableScroll() {
    document.body.removeEventListener('scroll', preventDefault, {
      passive: true
    });
  }

  $navToggle.addEventListener('click', function () {
    if ($header.classList.contains('js-menuDrawer-is-open')) {
      enableScroll();
      $header.classList.remove('js-menuDrawer-is-open');
      $body.classList.remove('js-menuDrawer-is-open');
    } else {
      disableScroll();
      $header.classList.add('js-menuDrawer-is-open');
      $body.classList.add('js-menuDrawer-is-open');
    }
  });
  $backdrop.addEventListener('click', function () {
    enableScroll();
    $header.classList.remove('js-menuDrawer-is-open');
    $body.classList.remove('js-menuDrawer-is-open');
  });
}; // navToggle


var _default = navToggle;
exports["default"] = _default;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function scrollTo(element) {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    // top: element.offsetTop,
    top: element.getBoundingClientRect().top + window.scrollY
  });
} // header contact us scroll to section


var _scrollToEl = function () {
  var $scrollToAnchors = document.querySelectorAll('[href^="#"]');
  $scrollToAnchors.forEach(function ($anchor) {
    $anchor.addEventListener('click', function (e) {
      e.preventDefault();
      var $scrollToTarget = document.querySelector($anchor.getAttribute('href')); // const contactUsOffsetTop = $scrollToTarget.getBoundingClientRect().top;
      // scrollTo(document.documentElement, contactUsOffsetTop, 800);

      scrollTo($scrollToTarget);
    });
  });
}();

var _default = _scrollToEl;
exports["default"] = _default;

},{}],4:[function(require,module,exports){
(function (global){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bsNative = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// Native Javascript for Bootstrap 4 v2.0.15 | © dnp_theme | MIT-License
var bsNative = function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD support:
    define([], factory);
  } else if ((typeof module === "undefined" ? "undefined" : _typeof(module)) === 'object' && module.exports) {
    // CommonJS-like:
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    var bsn = factory();
    root.Collapse = bsn.Collapse;
    root.Dropdown = bsn.Dropdown;
    root.Popover = bsn.Popover;
  }
}(void 0, function () {
  /* Native Javascript for Bootstrap 4 | Internal Utility Functions
  ----------------------------------------------------------------*/
  "use strict"; // globals

  var globalObject = typeof global !== 'undefined' ? global : this || window,
      doc = document.documentElement,
      body = document.body,
      // function toggle attributes
  dataToggle = 'data-toggle',
      // components
  stringCollapse = 'Collapse',
      stringDropdown = 'Dropdown',
      // options DATA API
  dataTarget = 'data-target',
      // option keys
  target = 'target',
      // box model
  offsetWidth = 'offsetWidth',
      scrollHeight = 'scrollHeight',
      height = 'height',
      // aria
  ariaExpanded = 'aria-expanded',
      // event names
  clickEvent = 'click',
      keydownEvent = 'keydown',
      // originalEvents
  showEvent = 'show',
      shownEvent = 'shown',
      hideEvent = 'hide',
      hiddenEvent = 'hidden',
      // other
  getAttribute = 'getAttribute',
      setAttribute = 'setAttribute',
      getElementsByTagName = 'getElementsByTagName',
      querySelectorAll = 'querySelectorAll',
      indexOf = 'indexOf',
      parentNode = 'parentNode',
      length = 'length',
      toLowerCase = 'toLowerCase',
      Transition = 'Transition',
      Webkit = 'Webkit',
      style = 'style',
      active = 'active',
      showClass = 'show',
      collapsing = 'collapsing',
      left = 'left',
      right = 'right',
      top = 'top',
      bottom = 'bottom',
      // transitionEnd since 2.0.4
  supportTransitions = Webkit + Transition in doc[style] || Transition[toLowerCase]() in doc[style],
      transitionEndEvent = Webkit + Transition in doc[style] ? Webkit[toLowerCase]() + Transition + 'End' : Transition[toLowerCase]() + 'end',
      // class manipulation, since 2.0.0 requires polyfill.js
  addClass = function addClass(element, classNAME) {
    element.classList.add(classNAME);
  },
      removeClass = function removeClass(element, classNAME) {
    element.classList.remove(classNAME);
  },
      hasClass = function hasClass(element, classNAME) {
    // since 2.0.0
    return element.classList.contains(classNAME);
  },
      // selection methods
  queryElement = function queryElement(selector, parent) {
    var lookUp = parent ? parent : document;
    return _typeof(selector) === 'object' ? selector : lookUp.querySelector(selector);
  },
      getClosest = function getClosest(element, selector) {
    //element is the element and selector is for the closest parent element to find
    // source http://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
    var firstChar = selector.charAt(0);

    for (; element && element !== document; element = element[parentNode]) {
      // Get closest match
      if (firstChar === '.') {
        // If selector is a class
        if (queryElement(selector, element[parentNode]) !== null && hasClass(element, selector.replace('.', ''))) {
          return element;
        }
      } else if (firstChar === '#') {
        // If selector is an ID
        if (element.id === selector.substr(1)) {
          return element;
        }
      }
    }

    return false;
  },
      // event attach jQuery style / trigger  since 1.2.0
  on = function on(element, event, handler) {
    element.addEventListener(event, handler, false);
  },
      off = function off(element, event, handler) {
    element.removeEventListener(event, handler, false);
  },
      one = function one(element, event, handler) {
    // one since 2.0.4
    on(element, event, function handlerWrapper(e) {
      handler(e);
      off(element, event, handlerWrapper);
    });
  },
      emulateTransitionEnd = function emulateTransitionEnd(element, handler) {
    // emulateTransitionEnd since 2.0.4
    if (supportTransitions) {
      one(element, transitionEndEvent, function (e) {
        handler(e);
      });
    } else {
      handler();
    }
  },
      bootstrapCustomEvent = function bootstrapCustomEvent(eventName, componentName, related) {
    var OriginalCustomEvent = new CustomEvent(eventName + '.bs.' + componentName);
    OriginalCustomEvent.relatedTarget = related;
    this.dispatchEvent(OriginalCustomEvent);
  },
      // Init DATA API
  initializeDataAPI = function initializeDataAPI(component, constructor, collection) {
    for (var i = 0; i < collection[length]; i++) {
      new constructor(collection[i]);
    }
  },
      // tab / collapse stuff
  targetsReg = /^\#(.)+$/;
  /* Native Javascript for Bootstrap 4 | Collapse
  -----------------------------------------------*/
  // COLLAPSE DEFINITION
  // ===================


  var Collapse = function Collapse(element, options) {
    // initialization element
    element = queryElement(element); // set options

    options = options || {}; // event targets and constants

    var collapse = null,
        self = this,
        isAnimating = false,
        // when true it will prevent click handlers
    // component strings
    component = 'collapse',
        collapsed = 'collapsed',
        // private methods
    openAction = function openAction(collapseElement) {
      bootstrapCustomEvent.call(collapseElement, showEvent, component);
      isAnimating = true;
      addClass(collapseElement, collapsing);
      removeClass(collapseElement, component);
      collapseElement[style][height] = collapseElement[scrollHeight] + 'px';
      emulateTransitionEnd(collapseElement, function () {
        isAnimating = false;
        collapseElement[setAttribute](ariaExpanded, 'true');
        removeClass(collapseElement, collapsing);
        addClass(collapseElement, component);
        addClass(collapseElement, showClass);
        collapseElement[style][height] = '';
        bootstrapCustomEvent.call(collapseElement, shownEvent, component);
      });
    },
        closeAction = function closeAction(collapseElement) {
      bootstrapCustomEvent.call(collapseElement, hideEvent, component);
      isAnimating = true;
      collapseElement[style][height] = collapseElement[scrollHeight] + 'px'; // set height first

      removeClass(collapseElement, component);
      addClass(collapseElement, collapsing);
      collapseElement[offsetWidth]; // force reflow to enable transition

      collapseElement[style][height] = '0px';
      emulateTransitionEnd(collapseElement, function () {
        isAnimating = false;
        collapseElement[setAttribute](ariaExpanded, 'false');
        removeClass(collapseElement, collapsing);
        addClass(collapseElement, component);
        removeClass(collapseElement, showClass);
        collapseElement[style][height] = '';
        bootstrapCustomEvent.call(collapseElement, hiddenEvent, component);
      });
    },
        getTarget = function getTarget() {
      var href = element.href && element[getAttribute]('href'),
          parent = element[getAttribute](dataTarget),
          id = href || parent && targetsReg.test(parent) && parent;
      return id && queryElement(id);
    }; // public methods


    this.toggle = function (e) {
      e.preventDefault();
      if (isAnimating) return;

      if (!hasClass(collapse, showClass)) {
        self.show();
      } else {
        self.hide();
      }
    };

    this.hide = function () {
      closeAction(collapse);
      addClass(element, collapsed);
    };

    this.show = function () {
      openAction(collapse);
      removeClass(element, collapsed);
    }; // init


    if (!(stringCollapse in element)) {
      // prevent adding event handlers twice
      on(element, clickEvent, this.toggle);
    }

    collapse = getTarget();
    element[stringCollapse] = this;
  }; // COLLAPSE DATA API
  // =================


  initializeDataAPI(stringCollapse, Collapse, doc[querySelectorAll]('[' + dataToggle + '="collapse"]'));
  /* Native Javascript for Bootstrap 4 | Dropdown
  ----------------------------------------------*/
  // DROPDOWN DEFINITION
  // ===================

  var Dropdown = function Dropdown(element, option) {
    // initialization element
    element = queryElement(element); // set option

    this.persist = option === true || element[getAttribute]('data-persist') === 'true' || false; // constants, event targets, strings

    var self = this,
        isOpen = false,
        parent = element[parentNode],
        component = 'dropdown',
        relatedTarget = null,
        menu = queryElement('.dropdown-menu', parent),
        children = menu[getElementsByTagName]('*'),
        // handlers
    keyHandler = function keyHandler(e) {
      if (isOpen && (e.which == 27 || e.keyCode == 27)) {
        relatedTarget = null;
        hide();
      } // e.keyCode for IE8

    },
        clickHandler = function clickHandler(e) {
      var eventTarget = e[target],
          hasData = eventTarget && (eventTarget[getAttribute](dataToggle) || eventTarget[parentNode] && getAttribute in eventTarget[parentNode] && eventTarget[parentNode][getAttribute](dataToggle));

      if (eventTarget === element || eventTarget === parent || eventTarget[parentNode] === element) {
        e.preventDefault(); // comment this line to stop preventing navigation when click target is a link 

        relatedTarget = element;
        self.toggle();
      } else if (isOpen) {
        if ((eventTarget === menu || children && [].slice.call(children)[indexOf](eventTarget) > -1) && (self.persist || hasData)) {
          return;
        } else {
          relatedTarget = null;
          hide();
        }
      }

      (/\#$/.test(eventTarget.href) || eventTarget[parentNode] && /\#$/.test(eventTarget[parentNode].href)) && e.preventDefault(); // should be here to prevent jumps
    },
        // private methods
    show = function show() {
      bootstrapCustomEvent.call(parent, showEvent, component, relatedTarget);
      addClass(menu, showClass);
      addClass(parent, showClass);
      menu[setAttribute](ariaExpanded, true);
      bootstrapCustomEvent.call(parent, shownEvent, component, relatedTarget);
      on(document, keydownEvent, keyHandler);
      isOpen = true;
    },
        hide = function hide() {
      bootstrapCustomEvent.call(parent, hideEvent, component, relatedTarget);
      removeClass(menu, showClass);
      removeClass(parent, showClass);
      menu[setAttribute](ariaExpanded, false);
      bootstrapCustomEvent.call(parent, hiddenEvent, component, relatedTarget);
      off(document, keydownEvent, keyHandler);
      isOpen = false;
    }; // public methods


    this.toggle = function () {
      if (hasClass(parent, showClass) && isOpen) {
        hide();
      } else {
        show();
      }
    }; // init


    if (!(stringDropdown in element)) {
      // prevent adding event handlers twice
      menu[setAttribute]('tabindex', '0'); // Fix onblur on Chrome | Safari

      on(document, clickEvent, clickHandler);
    }

    element[stringDropdown] = this;
  }; // DROPDOWN DATA API
  // =================


  initializeDataAPI(stringDropdown, Dropdown, doc[querySelectorAll]('[' + dataToggle + '="dropdown"]'));
  return {
    Collapse: Collapse,
    Dropdown: Dropdown
  };
});

exports.bsNative = bsNative;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],5:[function(require,module,exports){
"use strict";

var _bootstrapNative = _interopRequireDefault(require("./inc/bootstrap-native-4"));

var _headerFN2 = _interopRequireDefault(require("./inc/UI/_headerFN"));

var _navigation = _interopRequireDefault(require("./inc/_navigation"));

var _scrollTo = _interopRequireDefault(require("./inc/_scrollTo"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// doc ready & events
document.addEventListener("DOMContentLoaded", function () {
  (0, _headerFN2["default"])(); // uncomment after css and html is finished: also copy styles for custom from from wr_theme

  (0, _navigation["default"])();
}); // end doc ready
// all content loaded 

window.addEventListener('scroll', function () {
  (0, _headerFN2["default"])();
});

},{"./inc/UI/_headerFN":1,"./inc/_navigation":2,"./inc/_scrollTo":3,"./inc/bootstrap-native-4":4}]},{},[5]);
